<template>
  <b-img
    :src="appLogoImage"
    alt="logo"
    style="max-width: 100%"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const {
      appLogoImage,
    } = $themeConfig.app

    return { appLogoImage }
  },
}
</script>
